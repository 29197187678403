import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Table,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import { TimeFormat,DateFormat, DateThai,DateFormatJSON,MobileFormat, NumberFormat,RootURL } from '../../Utils/Function';
import parse from 'html-react-parser'
import "react-datepicker/dist/react-datepicker.css";
import CustomerInfoDetail from './CustomerInfoDetail';
const CustomerInfoCard = forwardRef((props,ref) =>{
    const [ModalState,setModalState] = useState(false);
    const [Detail,setDetail] = useState(null);
    const [_CustomerID,setCustomerID] = useState(null);
    const _ref = useRef();
    useEffect(() => {
        (async () => {
            
        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(CustomerID){
            setCustomerID(CustomerID);
            setModalState(true);
        },

      }));
    

    return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">ข้อมูลลูกค้า {(Detail != null?Detail.CustomerCode:"")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>

            <div className='modal-body' style={{'minHeight':'500px'}}>
                {(_CustomerID != null ? <CustomerInfoDetail CustomerID={_CustomerID} modal={(state,detail)=>{setDetail(detail);}} error={()=>{setModalState(false);}} success={()=>{setModalState(true);}} /> : "")}
            </div>
        </Modal>
        </>)
        
});

export default React.memo(CustomerInfoCard)