import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';
import { createRoot } from 'react-dom/client';

import Swal from 'sweetalert2';
import API from '../../API';

import PackageCreate from './PacakgeCreate';
import PackageGroupCreate from './PackageGroupCreate';

import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';

const PagePackageList =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const _GroupRef = useRef();
    const _PacakgeCreate = useRef();
    const _PackageGroupCreate = useRef();
    const [_PackageGroupList,setPackageGroupList] = useState([]);
    const [_PageMode,setPageMode] = useState(1);
    useEffect(() => {
        (async () => {
            await _LoadPackageGroup();
        })();

        return ()=>{ }
    }, []);
    const _LoadPackageGroup = async()=>{
        API({method : "get",url:"/Search/Package/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setPackageGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    
    
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Package/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.PackageGroupID = getValues('PackageGroupID');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _PacakgeCreate.current?.Show(d)}>Info</button>
            <button type='button' title='ลบ' className='btn btn-danger text-white' onClick={() => _DeletePackage(d,r)}>ลบ</button>
        </div>
    )
    const _column = [
        { data: "PackageCode", name: "PackageCode", autoWidth: true },
        { data: "PackageGroupName", name: "PackageGroupName", autoWidth: true },
        { data: "PackageName", name: "PackageName", autoWidth: true },
        { data: "Description", name: "Description", autoWidth: true },
        {data: "Price", name: "Price", autoWidth: true,render(data){
            return NumberFormat(data);
        }},
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "PackageId", name: "PackageID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _UpdateTable =()=>{
        ref.current?.load();
    }

    const _GroupConfigs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _GroupAjax = {
        'url': '/Package/Group/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
        }
    }
    const _GroupColumn = [
        { data: "PackageGroupName", name: "PackageGroupName", autoWidth: true },
        { data: "Description", name: "Description", autoWidth: true },
        {data: "QuantityLimit", name: "QuantityLimit", autoWidth: true,render(data){
            return NumberFormat(data);
        }},
        { data: "Remark", name: "Remark", autoWidth: true },
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "PackageGroupId", name: "PackageGroupId", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_GroupButtons(cellData,rowData))
        }
    ]
    const _GroupButtons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _PackageGroupCreate.current?.Show(d)}>Info</button>
            <button type='button' title='ลบ' className='btn btn-danger text-white' onClick={() => _DeleteGroup(d,r)}>ลบ</button>

        </div>
    )
    const _GroupColumnDefs = {

    }
    const _DeletePackage = async(PackageID,val)=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องลบแพ็คเก็จ '+val.PackageName+' หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Package/"+PackageID}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                ref.current?.load();
            }).catch(res => {
                return;
            });
        });
    }
    const _DeleteGroup = async(PackageGroupID,val) =>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องลบกลุ่มแพ็คเก็จ '+val.PackageGroupName+' หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Package/Group/"+PackageGroupID}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                ref.current?.load();
            }).catch(res => {
                return;
            });
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>จัดการแพ็คเกจ</h3>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row d-flex justify-content-end mb-4'>
                                    <div className='col-lg-3 text-end'>
                                        <div className='btn-group btn-group-sm'>
                                            <button type='button' disabled={(_PageMode === 1 ? true:false)} className={(_PageMode === 1 ?'btn btn-secondary text-white active':'btn btn-primary text-white')} style={{'width':'120px'}} onClick={()=>{setPageMode(1);}} >รายการแพ็คเกจ</button>
                                            <button type='button' disabled={(_PageMode === 2 ? true:false)} className={(_PageMode === 2 ?'btn btn-secondary text-white active':'btn btn-primary text-white')} style={{'width':'120px'}} onClick={()=>{setPageMode(2);}} >กลุ่มแพ็คเก็จ</button>
                                        </div>
                                    </div>
                                </div>
                                {(_PageMode === 1 ?
                                <>
                                    <div className='row d-flex justify-content-end'>
                                        <div className='col-4 col-lg-3 mt-2 text-end'>
                                            <div className="input-group mb-3">
                                                <select className='form-control text-center' {...register("PackageGroupID", { required: false })} defaultValue={0}>
                                                    <option value={0} selected>ทั้งหมด</option>
                                                    {_PackageGroupList.map((v,i)=>{
                                                        return <option key={i} value={v.PackageGroupId}>{v.PackageGroupName}</option>
                                                    })}
                                                </select>
                                                <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>ref.current.load()}>ค้นหา</button>
                                                <button type='button'  className='btn btn-primary text-white' onClick={()=>{_PacakgeCreate.current?.Show()}}>เพิ่มแพ็คเกจ</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12 table-responsive">
                                            <table id="PackageListView" className='table w-100' role='grid'>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center textcenter">รหัส</th>
                                                        <th className="text-center textcenter">กลุ่ม</th>
                                                        <th className="text-center textcenter">แพ็คแก็จ</th>
                                                        <th className="text-center textcenter">รายละเอียด</th>
                                                        <th className="text-center textright">ราคาต่อหน่วย</th>
                                                        <th className="text-center textcenter">วันที่แก้ไข</th>
                                                        <th className="text-center no-sort"></th>
                                                    </tr>
                                                </thead>
                                                <DataTable TableID={"PackageListView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                            </table>
                                        </div>
                                    </div>
                                </>:"")}
                                {(_PageMode === 2 ?
                                <>
                                    <div className='row d-flex justify-content-end'>
                                        <div className='col-4 col-lg-3 mt-2 text-end'>
                                            <button type='button'  className='btn btn-primary text-white' onClick={()=>{_PackageGroupCreate.current?.Show()}}>เพิ่มกลุ่มแพ็คเก็จ</button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12 table-responsive">
                                            <table id="PackageGroupView" className='table w-100' role='grid'>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center textcenter">กลุ่มแพ็คเก็จ</th>
                                                        <th className="text-center textleft">รายละเอียด</th>
                                                        <th className="text-center textright">จำนวนจำกัด</th>
                                                        <th className="text-center textleft">หมายเหตุ</th>
                                                        <th className="text-center textcenter">วันที่แก้ไข</th>
                                                        <th className="text-center no-sort"></th>
                                                    </tr>
                                                </thead>
                                                <DataTable TableID={"PackageGroupView"} Column={_GroupColumn} Configs={_GroupConfigs} ref={_GroupRef} ajax={_GroupAjax} ColumnDefs={_GroupColumnDefs} />
                                            </table>
                                        </div>
                                    </div>
                                </>:"")}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        <PackageCreate ref={_PacakgeCreate} update={()=>{ref.current?.load();}}  />
        <PackageGroupCreate ref={_PackageGroupCreate} update={()=>{_GroupRef.current?.load();}}/>
        </>
    )
}

export default React.memo(PagePackageList)
