import React,{useRef,useState,useEffect} from 'react';
import '../../Assets/CSS/Horapa.css';
import {CustomerInfoDetail} from '../../Components/Customer'
const CustomerPopup =()=> {
    const[_CustomerID,setCustomerID] = useState(null);
    useEffect(() => {
        (async () => {
            setCustomerID(window.location.search.substring(1));
        })();

        return ()=>{ }
    }, []);
    return (
        <>
        <React.Fragment>
            
            <div className='container-fluid'>
            {(_CustomerID != null ? <CustomerInfoDetail CustomerID={_CustomerID} /> : "")}

            </div>
        </React.Fragment>
        </>
    )
}

export default React.memo(CustomerPopup)
