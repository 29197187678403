import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, TimeFormat } from '../../Utils/Function';
import {SearchSubDistrict,SearchCustomer} from '../../Utils/Search';
import parse from 'html-react-parser'
import moment from 'moment';
import AsyncSelect from 'react-select/async';

const ProductTypeCreate = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const [ModalState,setModalState] = useState(false);
    const [_PackageGroupList,setPackageGroupList] = useState([]);
    const [_PriceList,setPriceList]=  useState([]);
    const [_FoodMenuList,setFoodMenuList] = useState([]);
    const [_FoodGroupList,setFoodGroupList] = useState([]);
    const [_Detail,setDetail] = useState(null);
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(PackageGroupID){            
            _ClearValuAction();

            if(PackageGroupID === undefined){
                setModalState(true);
            }else{
                _LoadDetail(PackageGroupID);
            }
        }

      }));
   
    const _LoadDetail = async (PackageGroupID)=>{
        API({method : "get",url:"/Package/Group/"+PackageGroupID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setDetail(res.data.Data);
            setFoodMenuList(res.data.Data.Menu);
            setPriceList(res.data.Data.Prices);
            setValue('PackageGroupName',res.data.Data.PackageGroupName);
            setValue('QuantityLimit',res.data.Data.QuantityLimit);
            setValue('Description',res.data.Data.Description);
            setValue('Remark',res.data.Data.Remark);

            setModalState(true);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: res.data.Message,
            });
            return;
        });
    }

    const _Save = async()=>{
        if(getValues('ProductTypeName') == undefined || getValues('ProductTypeName') === ""){
            Swal.fire({
                icon: 'error',
                title: 'ชื่อประเภทสินค้า'
            });
            return;
        }

        let _reqObj = {
            ProductTypeId:(_Detail != null ? _Detail.ProductTypeId:null),
            ProductTypeName: getValues('ProductTypeName'),
            Description: getValues('Description'),
            Remark: getValues('Remark'),
        }

        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Product/Type/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setModalState(false);
                _ClearValuAction();
                if(props.update != undefined){
                    try{
                        props.update(res.data.Data);
                    }catch(e){
                        console.log(e);
                    }
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        });
    }

    const _ClearValuAction = ()=>{
        reset();
    }
    const _ClearValue = ()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการล้างข้อมูล หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ClearValuAction();
        });
    }
      return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">{(_Detail != null ? "แก้ไขประเภทสินค้า":"สร้างประเภทสินค้า")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'300px'}}>
                <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                    <div className='row mt-4'>
                        <div className="col-md-5">
                            <label className="form-label">ชื่อประเภทสินค้า</label>
                                <input type='text' className="form-control" placeholder="ชื่อประเภทสินค้า" autoComplete="off"   {...register("ProductTypeName", { required: false })}/>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">รายละเอียด</label>
                            <Controller name="Description" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">หมายเหตุ</label>
                            <Controller name="Remark" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-md-12 mt-4 text-end'>
                            <div className='btn-group btn-group-lg'>
                                <button type="button" className='btn btn-danger' disabled={(_Detail != null ? true:false)} onClick={()=>{_ClearValue();}}>ล้างข้อมูล</button>
                                <button type="submit" className='btn btn-success'>บันทึก</button>
                            </div>
                        </div>
                    </div>
                    </Form>
            </div>
        </Modal>
        </>
      )
});
export default React.memo(ProductTypeCreate);