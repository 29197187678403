import React,{forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import {APIURL,GetAuthorization} from '../../Utils/Function';
import moment from 'moment';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const FilePreviewCard = forwardRef((props,ref) =>{
    const [_ModalState,setModalState] = useState(false);
    const [_FileObj,setFileObj] = useState(null);
    useEffect(() => {
        (async () => {
      
        })();

        return ()=>{ 
        }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Open(FileObj){
            setFileObj(FileObj);
            console.log(FileObj);
            setModalState(true);
            console.log(FileObj.FileType);
            console.log(String(FileObj.FileType).substring(0,'image/'.length));
        }
      }));


      const _PDFView = (
        <>
            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                <Viewer fileUrl={APIURL()+'/File/'+GetAuthorization()+'/'+(_FileObj != null ? _FileObj.FileID:null)} plugins={[defaultLayoutPlugin]}/>
            </Worker>       
        </>
    )

      const _ImageView =(
        <>
            <div className='row'>
                <div className='col-12'>
                    <img className='form-control' src={APIURL()+'/File/'+GetAuthorization()+'/'+(_FileObj != null ? _FileObj.FileID:null)} />
                </div>
            </div>
        </>
    )
      return (
        <>
            <Modal isOpen={_ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
                <div className="modal-header">
                    <h5 className="modal-title">{(_FileObj!= null ? _FileObj.FileName:'')}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
                </div>
                <div className='modal-body'>
                    {(_FileObj != null && String(_FileObj.FileType) === 'application/pdf' ? _PDFView:'')}
                    {(_FileObj != null && String(_FileObj.FileType).substring(0,'image/'.length) === 'image/' ? _ImageView:'')}
                </div>
            </Modal>
        </>
        )

});

export default React.memo(FilePreviewCard)