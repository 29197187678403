import React,{useState,useEffect} from "react";
import Header from "./Header";
import Footer from './Footer';
import {IsLogged, RootURL} from '../Utils/Function';
import Swal from 'sweetalert2';
import API from '../API';

const Content =(props) =>{
    const [_Login,setLogin] = useState(true);
    // eslint-disable-next-line
    useEffect(() => {
        (async () => {
            _SessionStatus();
        })();
        
        
        const _Interval = setInterval(() => {
            _SessionStatus();

        }, 600000);
        return ()=>{ 
            clearInterval(_Interval);
        }
        // eslint-disable-next-line
    }, []);
    const _SessionStatus = async()=>{
        if(!_Login || !IsLogged()) return;
            
            API({method : "get",url:"/Authorized/Status/"+sessionStorage.getItem("Authorization")}).then(res=>{
                if (res.status !== 200) {
                    setLogin(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    }).then(()=>{
                        sessionStorage.clear();
                        window.location = RootURL();
                    });
                    return;
                }
    
                if (!res.data.Status) {
                    setLogin(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    }).then(()=>{
                        sessionStorage.clear();
                        window.location = RootURL();
                    });
                    return;
                }

            }).catch(res => {
                setLogin(false);
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                }).then(()=>{
                    sessionStorage.clear();
                    window.location = RootURL();
                });
            });
    }
    const _Popup = ()=>{
        if(window.location.pathname.includes('/Popup/')) return true; else return false;
    }
    return (
        <React.Fragment>
            {(IsLogged() && !_Popup()? <Header/> : "")}
            <main role='main' className='flex-shrink-0 mt-2 mb-2'>
            {props.children}
            </main>
            {(IsLogged() && !_Popup()? <Footer/> : "")}

        </React.Fragment>
    )
}
export default React.memo(Content)