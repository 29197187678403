import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Table,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import { TimeFormat,DateFormat, DateThai,DateFormatJSON,MobileFormat, NumberFormat,RootURL } from '../../Utils/Function';
import parse from 'html-react-parser'
import "react-datepicker/dist/react-datepicker.css";

const CustomerInfoDetail = forwardRef((props,ref) =>{
    const [Detail,setDetail] = useState(null);
    const [ViewMode,setViewMode] = useState(1);
    const [Items,setItems] = useState([]);
    useEffect(() => {
        (async () => {
            _Load();
            setItems(1);
            console.log('detail');

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({

    }));
    const _Load=async()=>{
        if(props.CustomerID === undefined) return;
        Swal.showLoading();

        API({method : "get",url:"/Customer/"+props.CustomerID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.data.Message,
                });
                if(props.error != undefined){
                    try{
                        props.error();
                    }catch(e){
                        console.log(e);
                    }
                }
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                if(props.error != undefined){
                    try{
                        props.error();
                    }catch(e){
                        console.log(e);
                    }
                }
                return;
            }
            Swal.close();
            setDetail(res.data.Data);
            if(props.modal != undefined){
                try{
                    props.modal(true,res.data.Data);
                }catch(e){
                    console.log(e);
                }
            }
            //setModalState(true);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: 'การเชื่อมต่อผิดพลาด',
                text: res.message+' ['+res.code+']',
            });
            if(props.error != undefined){
                try{
                    props.error();
                }catch(e){
                    console.log(e);
                }
            }
        });
    }

    return (
        <>
                <div className='row'>
                    <div className='col-lg-10'>
                        <div className='btn-group btn-lg'>
                            <button type="button" className={(ViewMode === 1 ? 'btn btn-info text-white active':'btn btn-secondary text-white')} onClick={()=>{setViewMode(1)}}>ข้อมูลลูกค้า</button>
                            <button type="button" disabled={(Detail != null && Detail.AccountType === 1?false:true)} className={(ViewMode === 2 ? 'btn btn-info text-white active':'btn btn-secondary text-white')} onClick={()=>{setViewMode(2)}}>ข้อมูลใบกำกับภาษี</button>
                        </div>
                    </div>
                    <div className='col-lg-2 fs-5 text-end'></div>
                </div>
                
                {(ViewMode === 1 ? <>
                    <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mt-3'>
                                    <div className="col-md-2">
                                        <label className="form-label">รหัสลูกค้า</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.CustomerCode:"")}  />
                                    </div>
                                    <div className="col-md-4">
                                        <label className="form-label">ชื่อลูกค้า</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.Name:"")}  />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">เบอร์โทร</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.Mobile:"")}  />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">ชื่อเล่น</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.NickName:"")}  />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">วันเกิดลูกค้า</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?DateFormat(Detail.BrithDay,false):"")}  />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-2">
                                        <label className="form-label">ประเภท</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.AccountTypeName:"")}  />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">กลุ่มลูกค้า</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null && Detail.CustomerGroup != null?Detail.CustomerGroup.CustomerGroupName:"")}  />

                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">ประเภทลูกค้า</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null && Detail.CustomerType != null?Detail.CustomerType.CustomerTypeName:"ไม่ระบุ")}  />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-5">
                                        <label className="form-label">ที่อยู่</label>
                                        <textarea className="form-control" readOnly={true} defaultValue={(Detail != null?Detail.Address:"")}/>

                                    </div>
                                    <div className="col-md-5">
                                        <label className="form-label">ตำบล / อำเภอ / จังหวัด</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.Subdistrict.FullName:"")}  />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">รหัสไปรษณีย์</label>
                                        <input type='text' className="form-control text-center" readOnly={true} value={(Detail != null?Detail.ZipCode:"")}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </>:"")}
                {(ViewMode === 2 ? <>
                    <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row mt-3'>
                                    <div className="col-md-6">
                                        <label className="form-label">ชื่อธุรกิจ</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.TaxName:"")}  />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">เลขประจำตัวผู้เสียภาษี</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.TaxID:"")}  />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="form-label">สำนักงาน/สาขา</label>
                                        <input type='text' className="form-control" readOnly={true} value={(Detail != null?Detail.TaxBranchName:"")}  />
                                    </div>

                                </div>
                                <div className='row mt-3'>
                                    <div className="col-md-5">
                                        <label className="form-label">ที่อยู่จัดงาน</label>
                                        <textarea className="form-control" readOnly={true} defaultValue={(Detail != null?Detail.TaxAddress:"")}/>

                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">รหัสไปรษณีย์</label>
                                        <input type='text' className="form-control text-center" readOnly={true} value={(Detail != null?Detail.TaxZipCode:"")}/>
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">เบอร์โทรศัพท์</label>
                                        <input type='text' className="form-control text-center" readOnly={true} value={(Detail != null?Detail.TaxPhone:"")}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                </>:"")}
        </>)
        
});

export default React.memo(CustomerInfoDetail)