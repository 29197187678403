const BankList = [
    {Code:'KBANK',Name:'ธนาคารกสิกรไทย'},
    {Code:'BAY',Name:'ธนาคารกรุงศรีอยุธยา'},
    {Code:'BBL',Name:'ธนาคารกรุงเทพ'},
    {Code:'KTB',Name:'ธนาคารกรุงไทย'},
    {Code:'TTB',Name:'ธนาคารทหารไทยธนชาต'},
    {Code:'SCB',Name:'ธนาคารไทยพาณิชย์'},
    {Code:'KKP',Name:'ธนาคารเกียรตินาคินภัทร'},
    {Code:'CIMBT',Name:'ธนาคารซีไอเอ็มบีไทย'},
    {Code:'UOBT',Name:'ธนาคารยูโอบี'},
    {Code:'TISCO',Name:'ธนาคารทิสโก้'},
    {Code:'CREDIT',Name:'ธนาคารไทยเครดิต'},
    {Code:'LHFG',Name:'ธนาคารแลนด์ แอนด์ เฮ้าส์'},
    {Code:'BAAC',Name:'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร'},
    {Code:'GSB',Name:'ธนาคารออมสิน'},
    {Code:'GHB',Name:'ธนาคารอาคารสงเคราะห์'},
]

const BankObject = (Code)=>{
    let _Obj=undefined;
    BankList.find((v,i)=>{
        if(v.Code === Code){
            _Obj = v;
        }
    });
    return _Obj;
}
const BankName = (Code)=>{
    let _Obj=undefined;
    BankList.find((v,i)=>{
        if(v.Code === Code){
            _Obj = v;
        }
    });
    if(_Obj === undefined) return '';
    return _Obj.Name;
}
export {BankList,BankObject,BankName}