import React,{forwardRef,useImperativeHandle,useEffect, useState } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import moment from 'moment';
import PackageInfoView from './PackageInfoView';
import ProductInfoView from './ProductInfoView';
const ProductView = forwardRef((props,ref) =>{
    const [_ViewMode,setViewMode] = useState(0);
    const [_Name,setName] = useState('');
    const [_ModalState,setModalState] = useState(false);
    const [_RefID,setRefID] = useState(0);
    useEffect(() => {
        (async () => { })();
        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({ 

        Show(RefType,RefID,Name){
            setViewMode(RefType);
            setRefID(RefID);
            setName(Name);
            setModalState(true);
            console.log(RefType+'=>'+RefID);
        }
    }));

    return (
        <>
            <Modal isOpen={_ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
                <div className="modal-header">
                    <h5 className="modal-title">{(_ViewMode === 1 ? "แพ็คเก็จ":'')}{(_ViewMode === 2 ? "สินค้า":'')} : {_Name}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
                </div>
                <div className='modal-body'>
                    {(_ViewMode === 1 ? <PackageInfoView PackageID={_RefID} /> :'')}
                    {(_ViewMode === 2 ? <ProductInfoView ProductID={_RefID} /> :'')}

                </div>
            </Modal>
        </>
    );
});

export default React.memo(ProductView)