import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, TimeFormat } from '../../Utils/Function';
import {SearchSubDistrict,SearchCustomer} from '../../Utils/Search';
import parse from 'html-react-parser'
import moment from 'moment';
import AsyncSelect from 'react-select/async';

import "react-datepicker/dist/react-datepicker.css";

const QuotationItemNewCard = forwardRef((props,ref) =>{

    const[ModalItemState,setModalItemState] = useState(false);
    
    const [PackageGroupList,setPackageGroupList] = useState([]);
    const [_PackageList,setPackageList] = useState([]);
    const [_ProductTypeList,setProductTypeList] = useState([]);
    const [_ProductList,setProductList] = useState([]);
    const [_ItemRefID,setItemRefID] = useState(0);
    const [_ItemEventDate,setItemEventDate] = useState(null);

    const[ItemMode,setItemMode] = useState(1);
    const[_QuotationEventID,setQuotationEventID] = useState(null);
    const[_Items,setItems] = useState(null);
    const[_Event,setEvent] = useState(null);
    const[_Index,setIndex] = useState(0);
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(EventIndex,Event){
            setIndex(EventIndex);
            _ClearValuAction();
            setEvent(Event);
            setItemEventDate(Event.EventDate)
            setQuotationEventID(Event.QuotationEventId);
            setItems(Event.Items);
            _GetPackageGroupList();
            _GetProductTypeList();
            setModalItemState(true);
            console.log(Event.Items);
        }
      }));


    const _GetPackageGroupList = ()=>{
        setPackageGroupList([]);
        API({method : "get",url:"/Search/Package/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setPackageGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetPackageList = (GroupID)=>{
        setPackageList([]);
        API({method : "get",url:"/Search/Package?PackageGroupID="+GroupID+"&Event="+_ItemEventDate}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setItemRefID(GroupID);
            setPackageList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetProductTypeList = ()=>{
        setProductTypeList([]);
        API({method : "get",url:"/Search/Product/Type"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setProductTypeList(res.data.Data);
        }).catch(res => {
            return;
        });
    }

    const _GetProductList = (TypeID)=>{
        setProductList([]);
        API({method : "get",url:"/Search/Product?ProductTypeID="+TypeID+"&Event="+_ItemEventDate}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setItemRefID(TypeID);
            setProductList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    const _ClearValuAction = ()=>{

    }
    const _ItemChangeEvent = (EventKey)=>{
        setItemEventDate(EventKey);
        if(EventKey === '0'){
            setPackageList([]);
            setProductList([]);
            return;
        }
        if(ItemMode === 1 && _ItemRefID > 0){
            _GetPackageList(_ItemRefID);
        }
        if(ItemMode === 2 && _ItemRefID > 0){
            _GetProductList(_ItemRefID);
        }
    }
    const _NewItemPackage = (PackageObj)=>{
        let _Item = {
            QuotationEventId:_QuotationEventID,
            LineNumber:_Items.length+1,
            EventKey:_ItemEventDate,
            RefType:1,
            RefID:PackageObj.PackageId,
            Name : PackageObj.PackageName,
            Description : PackageObj.Description,
            GroupName : PackageObj.PackageGroupName,
            GroupId:PackageObj.PackageGroupId,
            UnitPrice : PackageObj.Price,
            PackagePriceId : 0,
            TotalAmount:0,
            DiscountAmount:0,
            FOC:false,
            Promotion:null,
            Remark:null

        }
        console.log(_Item);
        let _ItemsList = [..._Items,_Item];
        //_ItemTotalCal(_ItemsList);
        _Update(_ItemsList);
        setModalItemState(false);
    }
    const _NewItemProduct = (ProductObj)=>{
        let _Item = {
            QuotationEventId:_Event.QuotationEventId,
            LineNumber:_Items.length+1,
            RefType:2,
            RefID:ProductObj.ProductId,
            Name : ProductObj.ProductName,
            Description : ProductObj.Description,
            GroupName : ProductObj.ProductTypeName,
            GroupId:ProductObj.ProductTypeId,
            UnitPrice : ProductObj.Price,
            PackagePriceId : 0,
            TotalAmount:0,
            DiscountAmount:0,
            FOC:false,
            Promotion:null,
            Remark:null
        }
        let _ItemsList = [..._Items,_Item];
       // _ItemTotalCal(_ItemsList);
       _Update(_ItemsList);
        setModalItemState(false);
    }
    const _Update = (ItemList)=>{
        if(props.update != undefined){
            try{
                props.update(_Index,ItemList);
            }catch(e){
                console.log(e);
            }
        }
    }
    return (
        <>
        <Modal isOpen={ModalItemState} className={'modal-xl'} centered={true} unmountOnClose={true}>
        <div className="modal-header">
                <h5 className="modal-title">เลือกรายการแพ็คเกจ / สินค้า</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalItemState(false)}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'500px'}}>
                <div className='row justify-content-between'>
                    <div className='col-lg-3'>
                        <div className="btn-group">
                            <button className={(ItemMode === 1 ? "btn btn-primary active": "btn btn-secondary")} onClick={()=>{setItemMode(1);}}>แพ็คแก็จ</button>
                            <button className={(ItemMode === 2 ? "btn btn-primary active": "btn btn-secondary")} onClick={()=>{setItemMode(2);}}>สินค้า</button>
                        </div>
                    </div>
                    <div className='col-lg-3 align-self-end align-self-lg-end text-end'>
                            {(ItemMode === 1 ?
                                <select className='form-control' disabled={(_ItemEventDate === 0 ? true : false)} style={{'width':'200px'}} onChange={(e)=>{_GetPackageList(e.currentTarget?.value);}}>
                                    <option value={0} selected={true} className='text-center'>เลือกกลุ่ม</option>
                                    {PackageGroupList.map((v,i)=>(
                                        <option key={i} value={(v.PackageGroupId)}>{v.PackageGroupName}</option> 
                                    ))}
                                </select>
                            :"")}
                            {(ItemMode === 2 ?
                                <select className='form-control' disabled={(_ItemEventDate === 0 ? true : false)} style={{'width':'200px'}} onChange={(e)=>{_GetProductList(e.currentTarget?.value);}}>
                                    <option value={0} selected={true} className='text-center'>เลือกประเภท</option>
                                    {_ProductTypeList.map((v,i)=>(
                                        <option key={i} value={(v.ProductTypeId)}>{v.ProductTypeName}</option> 
                                    ))}
                                </select>
                            :"")}
                    </div>
                </div>
                {(ItemMode === 1 ?<div className='row mt-3'>
                    <div className='col-lg-12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='text-center'>รหัส</th>
                                    <th className='text-center'>แพ็คแก็จ</th>
                                    <th className='text-center'>รายละเอียด</th>
                                    <th className='text-center'>ราคาต่อหน่วย</th>
                                    <th className='text-center'>จำนวนงาน</th>
                                    <th className='text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {_PackageList.map((v,i)=>(
                                 <tr key={i}>
                                    <td className='text-center'>{v.PackageCode}</td>
                                    <td className='text-left'>{v.PackageName}</td>
                                    <td className='text-left'>{v.Description}</td>
                                    <td className='text-end'>{NumberFormat(v.Price)}</td>
                                    <td className='text-center'> {NumberFormat(v.QuoteQuantity)} / {NumberFormat(v.DepositQuantity)} / {NumberFormat(v.QuantityLimit)}</td>
                                    <td className='text-center'>
                                        <button className='btn btn-success btn-sm' onClick={()=>{_NewItemPackage(v);}}>เลือก</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>:"" )}
                {(ItemMode === 2 ?<div className='row mt-3'>
                    <div className='col-lg-12'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className='text-center'>สินค้า</th>
                                    <th className='text-center'>รายละเอียด</th>
                                    <th className='text-center'>ราคาต่อหน่วย</th>
                                    <th className='text-center'>จำนวนงาน</th>
                                    <th className='text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {_ProductList.map((v,i)=>(
                                 <tr key={i}>
                                    <td className='text-left'>{v.ProductName}</td>
                                    <td className='text-left'>{v.Description}</td>
                                    <td className='text-end'>{NumberFormat(v.Price)}</td>
                                    <td className='text-center'> {NumberFormat(v.QuoteQuantity)} / {NumberFormat(v.DepositQuantity)} / {NumberFormat(v.QuantityLimit)}</td>
                                    <td className='text-center'>
                                        <button className='btn btn-success btn-sm' onClick={()=>{_NewItemProduct(v);}}>เลือก</button>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>:"" )}
            </div>
        </Modal>
        </>)
        
});

export default React.memo(QuotationItemNewCard)