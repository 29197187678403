import React,{forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Modal} from "reactstrap";
import Swal from 'sweetalert2';
import API from '../../API';
import moment from 'moment';

const UploadCard = forwardRef((props,ref) =>{
    const [_Accept,setAccept] = useState('.png,.pdf,.jpg,.jpeg');
    const _FileInputRef = useRef();
    const [_RefType,setRefType] = useState('');
    const [_RefID,setRefID] = useState('');
    //const [_After,setAfter] = useState(undefined);
    useEffect(() => {
        (async () => {
      
        })();

        return ()=>{ 
        }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Open(RefType,RefID,Accept,After){
            setRefType(RefType);
            setRefID(RefID);
            if(Accept != undefined) setAccept(Accept); else setAccept('.png,.pdf,.jpg,.jpeg');
            _FileInputRef.current.value = null;
            _FileInputRef.current.click();
            _FileInputRef.current.After = After;
        }
      }));

      const _FileUpload = async(e)=>{
        const fd = new FormData();
		fd.append("File", _FileInputRef.current.files[0]);
        Swal.showLoading();
        API({method : "post",url:'/File/'+_RefType+'/'+_RefID,data:fd,headers: { "Content-Type": "multipart/form-data" } }).then(res => {

            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return;
            }
            const _After = _FileInputRef.current.After;
            if(_After != undefined){
                try{
                    _After(res.data.Data);
                }catch(e){}
            }
            Swal.close();
        })
        .catch(error => {
            if(error.response != undefined){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.Message
                });
                
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: error.message+' ['+error.code+']',
                });
            }
        });
      }
      return (
        <>
        <input type='file' onChange={(e)=>{_FileUpload(e)}} ref={_FileInputRef} style={{display: 'none'}} accept={_Accept}/>
        </>
      )
});

export default React.memo(UploadCard)