import API from '../API';

const SearchSubDistrict = async (Search)=>{
    return await API.get('/Search/Subdistrict/?Search='+Search,{responseType:'json'}).then((res)=>{
        if(!res.data.Status) return [];
        return res.data.Data;
    }).catch(res => {return [];});
}
const SearchPackage = async (Search,PackageGroupID=0)=>{
    return await API.get('/Search/Package/?Search='+Search+'&PackageGroupID='+PackageGroupID,{responseType:'json'}).then((res)=>{
        if(!res.data.Status) return [];
        return res.data.Data;
    }).catch(res => {return [];});
}

const SearchCustomer = async (Search=0)=>{
    return await API.get('/Search/Customer/?Search='+Search,{responseType:'json'}).then((res)=>{
        if(!res.data.Status) return [];
        return res.data.Data;
    }).catch(res => {return [];});
}
export {SearchSubDistrict,SearchPackage,SearchCustomer}