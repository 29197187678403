import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {PageNotFound,PageLogin,PageHome,PageUserOnline,PagePackageList,PageQuotationList,PageCustomerList,PageProductList,PageFoodList,PageBankAccountList,PageQuotationRemark,PagePromotionList} from './Pages';
import {CustomerPopup} from './Pages/Popup';
import {IsLogged,GetRole} from './Utils/Function';

const Router =() =>{
    if(IsLogged()){
        return (
            <Routes>
                <Route path="/Home" element={<PageQuotationList />} />
                <Route path="/Quotation" element={<PageQuotationList/>}/>
                <Route path="/Customer" element={<PageCustomerList/>}/>
                <Route path="/Popup/Customer/*" element={<CustomerPopup/>}/>

                {(GetRole() === 99 ? <Route path="/User/Online" element={<PageUserOnline />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Package" element={<PagePackageList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Product" element={<PageProductList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Food" element={<PageFoodList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/BankAccount" element={<PageBankAccountList />} /> :"")}
                {(GetRole() === 99 ? <Route path="/QuotationRemark" element={<PageQuotationRemark />} /> :"")}
                {(GetRole() === 99 ? <Route path="/Promotion" element={<PagePromotionList />} /> :"")}

                <Route path="*" element={<PageNotFound />} />
            </Routes>
        )
    }else{
        return (
            <Routes>
                <Route path="*" element={<PageLogin />} />
            </Routes>
        )
    }
    
}

export default React.memo(Router)