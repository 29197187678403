import API from '../../API';
import Swal from 'sweetalert2';

import FilePreviewCard from './FilePreviewCard';


const DeleteFile = async(FileID,AfterDelete)=>{
    Swal.fire({
        icon: 'warning',
        title: 'ต้องการลบไฟล์หรือไม่ ?',
        text : '',
        showCancelButton : true,
        confirmButtonText: 'ลบ',
        confirmButtonColor:'#d33',
        cancelButtonText:'ยกเลิก'
    }).then((r)=>{
        if(!r.isConfirmed){
            return;
        }
        Swal.isLoading();
        API({method : "delete",url:"/File/"+FileID}).then(res=>{
            if(!res.data.Status){
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message
                });
                return;
                
            }
            Swal.close();
            if(AfterDelete != undefined){
                try{
                    AfterDelete();
                }catch(e){

                }
                
            }
        }).catch(res => {
            if(res.response.data != undefined){
                Swal.fire({
                    icon: 'error',
                    title: res.code,
                    text: res.response.data.Message
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
            }
        });
    });
}

export {
    DeleteFile,
    FilePreviewCard,
}