import API from '../../API';
import Swal from 'sweetalert2';

const QuotationPDF = async(QuotationID) =>{
    Swal.fire('Please wait')
    Swal.showLoading()
    API({method : "get",url:"/Quotation/"+QuotationID+"/PDF"}).then(res=>{
        if (res.status !== 200) {
            Swal.close();
            return;
        }
        if (!res.data.Status) {
            Swal.fire({
                icon: 'error',
                title: res.data.Message
            });
            Swal.close();
            return;
        }
         var base64str = res.data.Data.PDF;
         var binary = atob(base64str.replace(/\s/g, ''));
         var len = binary.length;
         var buffer = new ArrayBuffer(len);
         var view = new Uint8Array(buffer);
         for (var i = 0; i < len; i++) {
             view[i] = binary.charCodeAt(i);
         }
         var blob = new Blob( [view], { type: "application/pdf" });
         var url = URL.createObjectURL(blob);
         window.open(url, '_blank');
         Swal.close();
    }).catch(res => {
        Swal.close();
        return;
    });
}
export {QuotationPDF}