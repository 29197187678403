import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';
import { createRoot } from 'react-dom/client';

import Swal from 'sweetalert2';
import API from '../../API';
import { BankList,BankName } from '../../Utils/Bank';
import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';
import { Modal } from 'reactstrap';

const PageBankAccountList =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const [_PageMode,setPageMode] = useState(1);
    const [_ModalState,setModalState] = useState(false);
    const [AccountType,setAccountType] = useState(1);
    const [BankCode,setBankCode] = useState('');
    const [BankBranch,setBankBranch] = useState('');
    const [AccountName,setAccountName] = useState('');
    const [AccountNo,setAccountNo] = useState('');
    const [Remark,setRemark] = useState('');

    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Setting/BankAccount/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {}
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' disabled={(r.Status === 2 ?true:false)} className='btn btn-info text-white' onClick={() => _SetDefault(d,r)}>ตั้งค่า</button>
            <button type='button' title='ลบ' className='btn btn-danger text-white' onClick={() => _Delete(d,r)}>ลบ</button>
        </div>
    )
    const _column = [
        {data: "AccountType", name: "AccountType", autoWidth: true,render(data){
            switch(data){
                case 1 : return 'บริษัท';
                case 2 : return 'ร้าน';
                default : return '';
            }
        }},
        { data: "BankName", name: "BankName", autoWidth: true },
        { data: "AccountName", name: "AccountName", autoWidth: true },
        { data: "BankBranch", name: "BankBranch", autoWidth: true },
        { data: "AccountNo", name: "AccountNo", autoWidth: true },
        {data: "Status", name: "Status", autoWidth: true,render(data){
            switch(data){
                case 1 : return 'สร้าง';
                case 2 : return 'ใช้งาน';
                case 0 : return 'ลบ';
            }
        }},
        { data: "Remark", name: "Remark", autoWidth: true },
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "AccoutingId", name: "AccoutingID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _UpdateTable =()=>{
        ref.current?.load();
    }


    const _Delete = async(AccoutingID,val)=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องลบบัญชี '+val.BankName +' หรือไม่ ?',
            text : val.AccountNo,
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Setting/BankAccount/"+AccoutingID}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                ref.current?.load();
            }).catch(res => {
                return;
            });
        });
    }
    const _SetDefault = async(AccoutingID,val)=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการตั้งค่าหลักบัญชี '+val.BankName +' หรือไม่ ?',
            text : val.AccountNo,
            showCancelButton : true,
            confirmButtonText: 'ตั้งค่า',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "put",url:"/Setting/BankAccount/"+AccoutingID}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                ref.current?.load();
            }).catch(res => {
                return;
            });
        });
    }

    const _CreateNew = ()=>{
        setAccountType(1);
        setBankCode('');
        setBankBranch('');
        setAccountName('');
        setAccountNo('');
        setRemark('');
        setModalState(true);
    }
    const _Save = async()=>{

        if(BankCode === ''){
            Swal.fire({
                icon: 'error',
                title: 'โปรดเลือกธนาคาร'
            });
            return;
        }

        if(BankBranch === ''){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุสาขา'
            });
            return;
        }

        if(AccountName === ''){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุชื่อบัญชี'
            });
            return;
        }
        if(AccountNo === ''){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบุเลขที่บัญชี'
            });
            return;
        }
        const _reqObj = {
            AccountType:AccountType,
            BankCode:BankCode,
            BankName:BankName(BankCode),
            BankBranch:BankBranch,
            AccoutName:AccountName,
            AccountNo:AccountNo,
            Remark:Remark
        }
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Setting/BankAccount/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setModalState(false);
                ref.current?.load();
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>จัดการข้อมูลธนาคาร</h3>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row d-flex justify-content-end'>
                                        <div className='col-4 col-lg-3 mt-2 text-end'>
                                            <button type='button'  className='btn btn-primary text-white' onClick={()=>{_CreateNew()}}>เพิ่มบัญชีธนาคาร</button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12 table-responsive">
                                            <table id="PackageListView" className='table w-100' role='grid'>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center textcenter">ประเภท</th>
                                                        <th className="text-center textcenter">ธนาคาร</th>
                                                        <th className="text-center textcenter">ชื่อบัญชี</th>
                                                        <th className="text-center textcenter">สาขา</th>
                                                        <th className="text-center textright">เลขที่บัญชี</th>
                                                        <th className="text-center textcenter">สถานะ</th>
                                                        <th className="text-center textcenter">หมายเหตุ</th>
                                                        <th className="text-center textcenter">แก้ไข</th>
                                                        <th className="text-center no-sort"></th>
                                                    </tr>
                                                </thead>
                                                <DataTable TableID={"PackageListView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                            </table>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        <Modal isOpen={_ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">เพิ่มข้อมูลบัญชีธนาคาร</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState()}}></button>
            </div>
            <div className='modal-body'>
                <div className="row mt-2 mb-2">
                    <div className="col-md-2">
                        <label className="form-label">ประเภท</label>
                        <select className='form-control text-center' onChange={(e)=>{setAccountType(e.target?.value);}} value={AccountType} >
                            <option value={0}>เลือกประเภท</option>
                            <option value={1}>บริษัท</option>
                            <option value={2}>ร้าน</option>
                        </select>
                    </div>
                    <div className="col-md-5">
                        <label className="form-label">ชื่อธนาคาร</label>
                        <select className='form-control text-left' onChange={(e)=>{setBankCode(e.target?.value);}} value={BankCode}>
                            <option value={''}>เลือกธนาคาร</option>
                            {BankList.map((v,i)=>(
                                <option key={i} value={v.Code}>{v.Name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-5">
                        <label className="form-label">สาขา</label>
                        <input type='text' className='form-control' value={BankBranch} onChange={(e)=>{setBankBranch(e.target.value);}}/>
                    </div>
                </div>
                <div className='row mt-2 mb-2'>
                    <div className="col-md-7">
                        <label className="form-label">ชื่อบัญชี</label>
                        <input type='text' className='form-control' value={AccountName} onChange={(e)=>{setAccountName(e.target.value);}}/>
                    </div>
                    <div className="col-md-5">
                        <label className="form-label">เลขที่บัญชี</label>
                        <input type='text' className='form-control' value={AccountNo} onChange={(e)=>{setAccountNo(e.target.value);}}/>
                    </div>
                </div>
                <div className='row mt-2 mb-2'>
                    <div className='col-md-12'>
                        <label className="form-label">หมายเหตุ</label>
                        <textarea className='form-control' value={Remark} onChange={(e)=>{setRemark(e.target.value);}}></textarea>
                    </div>
                </div>
                <div className='row mt-4 mb-2 justify-content-end'>
                    <div className='col-md-2 text-end'>
                        <button type='button' className='btn btn-success' onClick={(()=>{_Save();})}>บันทึก</button>
                    </div>
                </div>
            </div>
        </Modal>
        </>
    )
}

export default React.memo(PageBankAccountList)
