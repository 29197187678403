import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';

import { createRoot } from 'react-dom/client';
import CustomerCreate from './CustomerCreate';
import{CustomerInfoCard} from '../../Components/Customer';
import Swal from 'sweetalert2';
import API from '../../API';
import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';

const PageCustomerList =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const _CustomerInfoCard = useRef();
    const _CustomerCreate = useRef();
    const [CustomerGroupList,setCustomerGroupList] = useState([]);

    useEffect(() => {
        (async () => {
            await _LoadCustomerGroupList();
        })();

        return ()=>{ }
    }, []);
    const _LoadCustomerGroupList = async()=>{
        API({method : "get",url:"/Search/Customer/Group"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setCustomerGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    const _configs = {
        "order": [[0, "desc"]],
        pageLength: 25,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Customer/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {
            d.CustomerGroupID = getValues('CustomerGroupID');
        }
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='รายละเอียด' className='btn btn-info text-white' onClick={() => _CustomerInfoCard.current?.Show(d)}>Info</button>

        </div>
    )
    const _column = [
        { data: "CustomerCode", name: "CustomerCode", autoWidth: true },
        { data: "Name", name: "Name", autoWidth: true },
        { data: "Mobile", name: "Mobile", autoWidth: true },
        { data: "AccountType", name: "AccountType", autoWidth: true,render(data){
            switch(data){
                case 1:return 'บริษัท';
                case 2:return 'ร้าน';
            }
        } },
        { data: "CustomerGroupName", name: "CustomerGroupName", autoWidth: true },
        { data: "CityName", name: "CityName", autoWidth: true },
        { data: "CustomerLevelName", name: "CustomerLevelName", autoWidth: true },
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "CustomerId", name: "CustomerId", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _UpdateTable =()=>{
        ref.current?.load();
    }
    const _Quotation = ()=>{
        RedirectTo('/Quotation');
    }
    const _CreateCustomer = ()=>{
        _CustomerCreate.current?.Show();
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>จัดการข้อมูลลูกค้า</h3>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                            <div className='row d-flex justify-content-end'>
                                <div className='col-2 col-lg-2 mt-2 text-end'>
                                    <select className='form-control text-center' {...register("CustomerGroupID", { required: false })} defaultValue={0}>
                                        <option value={0} selected>ทั้งหมด</option>
                                        {CustomerGroupList.map((v,i)=>{
                                            return <option key={i} value={v.CustomerGroupId}>{v.CustomerGroupName}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='col-3 col-lg-3 text-end'>
                                    <div className='btn btn-group'>
                                        <button type='button' style={{'width':'80px'}} className='btn btn-info text-white' onClick={()=>ref.current.load()}>ค้นหา</button>
                                        <button type='button'  className='btn btn-warning text-white' onClick={()=>{_Quotation();}}>จัดการใบเสนอราคา</button>
                                        <button type='button'  className='btn btn-primary text-white' onClick={()=>{_CreateCustomer();}}>เพิ่มข้อมูลลูกค้า</button>
                                    </div>
                                </div>
                            </div>

                                <div className='row'>
                                    <div className="col-lg-12 table-responsive">
                                        <table id="CustomerTableView" className='table w-100' role='grid'>
                                            <thead>
                                                <tr>
                                                    <th className="text-center textcenter">รหัสลูกค้า</th>
                                                    <th className="text-center textcenter">ลูกค้า</th>
                                                    <th className="text-center textcenter">เบอร์โทร</th>
                                                    <th className="text-center textcenter">ประเภท</th>
                                                    <th className="text-center textcenter">กลุ่มลูกค้า</th>
                                                    <th className="text-center textcenter">พื้นที่</th>
                                                    <th className="text-center textcenter">MemberShip</th>
                                                    <th className="text-center textcenter">วันที่แก้ไข</th>
                                                    <th className="text-center no-sort"></th>
                                                </tr>
                                            </thead>
                                            <DataTable TableID={"CustomerTableView"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomerCreate ref={_CustomerCreate} />
            <CustomerInfoCard ref={_CustomerInfoCard} />
        </React.Fragment>
        </>
    )
}

export default React.memo(PageCustomerList)
