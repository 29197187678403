import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import Swal from 'sweetalert2';
import API from '../../API';
import {  NumberFormat,APIURL,GetAuthorization } from '../../Utils/Function';
import {FilePreviewCard} from '../';
const ProductInfoView = forwardRef((props,ref) =>{
    const [_Detail,setDetail] = useState(null);
    const [_FileList,setFileList] = useState([]);
    const _FilePreviewRef = useRef();
    useEffect(() => {
        (async () => {
            console.log(props.ProductID);
            _LoadDetail(props.ProductID);
            setFileList([]);
            _GetFileList(props.ProductID);
        })();
        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({


    }));
    const _LoadDetail = async(ProductID)=>{
        API({method : "get",url:"/Product/"+ProductID}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setDetail(res.data.Data);
            //console.log(res.data.Data);
        }).catch(res => {
            Swal.fire({
                icon: 'error',
                title: res.data.Message,
            });
            return;
        });
    }

    const _GetFileList = async(RefID)=>{
        setFileList([]);
        API({method : "get",url:"/File/PRODUCT/"+RefID}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setFileList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    return (
        <>
        <div className='row mt-2 mb-2'>
            <div class="col-md-10">
                <label class="form-label">ชื่อสินค้า</label>
                <div className='form-control'>{(_Detail != null ? _Detail.ProductName:'')}</div>
            </div>
        </div>
        <div className='row mt-2 mb-2'>
            <div class="col-md-3">
                <label class="form-label">กลุ่มแพ็คเก็จ</label>
                <div className='form-control'>{(_Detail != null ? _Detail.ProductType.ProductTypeName:'')}</div>
            </div>
            <div class="col-md-2">
                <label class="form-label">ราคา</label>
                <div className='form-control text-end'>{(_Detail != null ? NumberFormat(_Detail.Price):'')}</div>
            </div>
            <div class="col-md-2">
                <label class="form-label">จำนวนจำกัด</label>
                <div className='form-control text-end'>{(_Detail != null ? NumberFormat(_Detail.QuantityLimit):'')}</div>
            </div>
        </div>
        <div className='row mt-2 mb-2'>
            <div className='col-md-12'>
            <label class="form-label">รายละเอียด</label>
            <div className='form-control' style={{minHeight:'100px'}}>{(_Detail != null ? _Detail.Description:'')}</div>
            </div>
        </div>
        <div className='row mt-2 mb-2'>
            <div className='col-md-12'>
            <label class="form-label">รายละเอียดภายใน</label>
            <div className='form-control' style={{minHeight:'100px'}}>{(_Detail != null ? _Detail.Remark:'')}</div>
            </div>
        </div>
        <div className='row mt-5'>
            <div className='card'>
                <div className='card-body'>
                {_FileList.map((v,i)=>(
                    <div className='row mt-2 mb-2' key={i}>
                        <div className='col-lg-3'>
                            <a href='#' onClick={(e)=>{_FilePreviewRef.current.Open(v);}}>
                                <img className='form-control' src={APIURL()+'/File/'+GetAuthorization()+'/'+v.FileID+'/Preview'}/>
                            </a>
                        </div>
                        <div className='col-lg-6 fs-6'>{v.FileName}</div>
                        
                    </div>
                ))}
                </div>
            </div>
        </div>
        <FilePreviewCard ref={_FilePreviewRef} />

        </>
    )
});
export default React.memo(ProductInfoView);