import React,{createRef, forwardRef,useImperativeHandle,useEffect, useState,useRef } from 'react';
import { Form,Modal} from "reactstrap";
import Swal from 'sweetalert2';
import { Controller, useForm } from "react-hook-form";

import API from '../../API';
import { DateFormat,DateFormatJSON, DateThai,MobileFormat, NumberFormat,CleanMobile, TimeFormat,APIURL,GetAuthorization } from '../../Utils/Function';
import {SearchSubDistrict,SearchCustomer} from '../../Utils/Search';
import parse from 'html-react-parser'
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import {UploadCard,DeleteFile,FilePreviewCard} from '../../Components'
const ProductCreate = forwardRef((props,ref) =>{
    const { register, handleSubmit, watch, reset, getValues,setValue, control, formState: { errors } } = useForm();

    const [ModalState,setModalState] = useState(false);
    const [_GroupList,setGroupList] = useState([]);
    const [_PriceList,setPriceList]=  useState([]);
    const [_FoodMenuList,setFoodMenuList] = useState([]);
    const [_FoodGroupList,setFoodGroupList] = useState([]);
    const [_Detail,setDetail] = useState(null);
    const _UploadRef = useRef();
    const [_FileList,setFileList] = useState([]);
    const _FilePreviewRef = useRef();
    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    useImperativeHandle(ref, () => ({
        Show(ProductID){
            _ClearValuAction();
            _GetPackageGroupList();
            setFoodMenuList([]);
            setPriceList([]);
            setFileList([]);
            setDetail(null);
            _Initial();
            if(ProductID === undefined){
                setModalState(true);
            }else{
                _LoadDetail(ProductID);
            }        }
      }));
    const _Initial = ()=>{
        
    }
    const _LoadDetail = async (ProductID)=>{
        setFileList([]);
        API({method : "get",url:"/Product/"+ProductID}).then(res=>{
            if (res.status !== 200) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }

            if (!res.data.Status) {
                Swal.fire({
                    icon: 'error',
                    title: res.data.Message,
                });
                return;
            }
            setDetail(res.data.Data);
            setValue('PackageCode',res.data.Data.PackageCode);
            setValue('ProductName',res.data.Data.ProductName);
            setValue('ProductTypeID',res.data.Data.ProductTypeId);
            setValue('Price',res.data.Data.Price);
            setValue('QuantityLimit',res.data.Data.QuantityLimit);

            setValue('Description',res.data.Data.Description);
            setValue('Remark',res.data.Data.Remark);

            setValue('BenefitGroupID',res.data.Data.BenefitGroupID);
            setValue('GLRefCode',res.data.Data.GLRefCode);

            _UploadGetFileLsit(res.data.Data.ProductId)
            setModalState(true);
        }).catch(res => {
            if(res.response.data != undefined){
                Swal.fire({
                    icon: 'error',
                    title: res.code,
                    text: res.response.data.Message
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'การเชื่อมต่อผิดพลาด',
                    text: res.message+' ['+res.code+']',
                });
            }
            return;
        });
    }
    const _GetPackageGroupList = async()=>{
        setGroupList([]);
        API({method : "get",url:"/Search/Product/Type"}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setGroupList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
    const _Save = async()=>{
        if(getValues('ProductName') == undefined || getValues('ProductName') === ""){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบ ชื่อสินค้า'
            });
            return;
        }

        if(getValues('ProductTypeID') == undefined || Number(getValues('ProductTypeID')) === 0){
            Swal.fire({
                icon: 'error',
                title: 'โปรดเลือก กลุ่มสินค้า'
            });
            return;
        }

        if(getValues('Price') == undefined || isNaN(getValues('Price'))){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบ ราคา'
            });
            return;
        }
        if(getValues('QuantityLimit') == undefined || isNaN(getValues('QuantityLimit'))){
            Swal.fire({
                icon: 'error',
                title: 'โปรดตรวจสอบ จำนวนจำกัด'
            });
            return;
        }

        
        let _reqObj = {
            ProductId:(_Detail != null ? _Detail.ProductId:null),
            ProductTypeId : Number(getValues('ProductTypeID')),
            ProductName: getValues('ProductName'),
            QuantityLimit : Number(getValues('QuantityLimit')),
            Price : Number(getValues('Price')),
            Description: getValues('Description'),
            Remark: getValues('Remark'),
            BenefitGroupID:Number(getValues('BenefitGroupID')),
            GLRefCode: getValues('GLRefCode')
        }
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Product/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setModalState(false);
                _ClearValuAction();
                if(props.update != undefined){
                    try{
                        props.update(res.data.Data);
                    }catch(e){
                        console.log(e);
                    }
                }
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
            });
        });
    }

    const _ClearValuAction = ()=>{
        reset();
        setPriceList([]);
    }
    const _ClearValue = ()=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการล้างข้อมูล หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'ตกลง',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            _ClearValuAction();
        });
    }
    const _AddPriceItem = ()=>{
        let _ItemList = [... _PriceList];

        const _Item = {
            PackagePriceId:null,
            Price : 0,
            Remark : "",
            Quantity:0
        }
        _ItemList.push(_Item);
        setPriceList(_ItemList);
    }
    const _UpdatePriceItem = (Index,Value)=>{
        const _Item = [..._PriceList];
        let _ItemN = [];
        _Item.find((e,i) => {
            if(Index === i){
                _ItemN.push(Value);
            }else{
                _ItemN.push(e);
            }
        });
        setPriceList(_ItemN);
    }
    const _DeletePriceItem = (Index) =>{
        const _Item = [..._PriceList];
        let _ItemN = [];
        let _i=0;
        _Item.find((e,i) => {
            if(Index != i){
                _i++;
                e.LineNumber = _i;
                _ItemN.push(e);
            }
        });
        setPriceList(_ItemN);
    }
    const _UpdateMenuItem = (Index,Value)=>{
        const _Item = [..._FoodMenuList];
        let _ItemN = [];
        _Item.find((e,i) => {
            if(Index === i){
                _ItemN.push(Value);
            }else{
                _ItemN.push(e);
            }
        });
        setFoodMenuList(_ItemN);
    }
    const _UploadAddItem = (ProductID)=>{
        _UploadGetFileLsit(ProductID)
    }
    const _UploadGetFileLsit = async(ProductID)=>{
        setFileList([]);
        API({method : "get",url:"/File/PRODUCT/"+ProductID}).then(res=>{
            if (res.status !== 200) {
                return;
            }

            if (!res.data.Status) {
                return;
            }
            setFileList(res.data.Data);
        }).catch(res => {
            return;
        });
    }
      return (
        <>
        <Modal isOpen={ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">{(_Detail != null ? "แก้ไขสินค้า":"สร้างสินค้า")}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState(false)}}></button>
            </div>
            <div className='modal-body' style={{'minHeight':'300px'}}>
                <Form onSubmit={handleSubmit(_Save)} className='row g-3'>
                    <div className='row mt-4'>
                        <div className="col-md-8">
                            <label className="form-label">ชื่อสินค้า</label>
                                <input type='text' className="form-control" placeholder="ชื่อสินค้า" autoComplete="off"   {...register("ProductName", { required: false })}/>
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">กลุ่มสินค้า</label>
                            <Controller name="ProductTypeID" control={control} rules={{required:false}} render={({field})=>(
                                <select className='form-control text-left' onChange={(e)=>field.onChange(e)} value={field.value} defaultValue={field.value} >
                                    <option value={0}>เลือกกลุ่มสินค้า</option>
                                    {_GroupList.map((v,i)=>(
                                     <option value={v.ProductTypeId}>{v.ProductTypeName}</option>   
                                ))}
                                </select>
                            )} />
                        </div>
                       
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-2">
                            <label className="form-label">ราคา</label>
                            <input type='number' maxLength={5} min={0} className="form-control text-end" placeholder="ราคา" autoComplete="off"   {...register("Price", { required: false })}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">จำนวนจำกัด</label>
                            <input type='number' maxLength={5} min={0} className="form-control text-end" placeholder="ราคา" autoComplete="off"   {...register("QuantityLimit", { required: false })}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">รหัสทางบัญชี</label>
                            <input type='text' className="form-control text-left" placeholder="รหัสทางบัญชี" autoComplete="off"   {...register("GLRefCode", { required: false })}/>
                        </div>
                        <div className="col-md-2">
                            <label className="form-label">ประเภทรายได้</label>
                            <Controller name="BenefitGroupID" control={control} rules={{required:false}} render={({field})=>(
                                <select className='form-control text-left' onChange={(e)=>field.onChange(e)} value={field.value} defaultValue={field.value} >
                                    <option value={0}>เลือกประเภทรายได้</option>
                                    <option value={1}>บริษัท</option>
                                    <option value={2}>คู่ค้า</option>
                                </select>
                            )} />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">รายละเอียด</label>
                            <Controller name="Description" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-md-12">
                            <label className="form-label">หมายเหตุ</label>
                            <Controller name="Remark" control={control} rules={{required:false}} render={({field})=>(
                                <textarea className="form-control" autoComplete="off"  onChange={(e)=>field.onChange(e)} defaultValue={field.value}/>
                            )} />
                        </div>
                    </div>
                    {(_Detail != null?<>
                    <div className='row mt-4 mb-2'>
                        <div className='col-lg-12'>
                            <div className='card'>
                                <div className='card-header text-end'>
                                    <div className='btn-group btn-group-sm'>
                                        <button className='btn btn-success' type="button" onClick={()=>{_UploadRef.current.Open('PRODUCT',_Detail.ProductId,undefined,(e)=>{_UploadAddItem(_Detail.ProductId);})}}>Upload</button>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    {_FileList.map((v,i)=>(
                                        <div className='row mt-2 mb-2' key={i}>
                                            <div className='col-lg-3'>
                                                <a href='#' onClick={(e)=>{_FilePreviewRef.current.Open(v);}}>
                                                    <img className='form-control' src={APIURL()+'/File/'+GetAuthorization()+'/'+v.FileID+'/Preview'}/>
                                                </a>
                                            </div>
                                            <div className='col-lg-6 fs-6'>{v.FileName}</div>
                                            <div className='col-lg-3 text-center'>
                                                <button className='btn btn-danger' type='button' onClick={()=>{DeleteFile(v.FileID,()=>{_UploadGetFileLsit(_Detail.ProductId);});}}>ลบ</button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    </>:'')}
                    <div className='row mt-3'>
                        <div className='col-md-12 mt-4 text-end'>
                            <div className='btn-group btn-group-lg'>
                                <button type="button" className='btn btn-danger' disabled={(_Detail != null ? true:false)} onClick={()=>{_ClearValue();}}>ล้างข้อมูล</button>
                                <button type="submit" className='btn btn-success'>บันทึก</button>
                            </div>
                        </div>
                    </div>
                </Form>
                
                
            </div>
        </Modal>
        <UploadCard ref={_UploadRef} />
        <FilePreviewCard ref={_FilePreviewRef} />
        </>
      )
});
export default React.memo(ProductCreate);