import React,{useRef,useState,useEffect} from 'react';
import {DataTable,QuotationInfoCard} from '../../Components'
import { useNavigate } from 'react-router-dom';
import { Controller, set, useForm } from "react-hook-form";

import {NumberFormat,MobileFormat,DateThaiFormat,DateFormat,DateThai,GetRole} from '../../Utils/Function';
import { createRoot } from 'react-dom/client';

import Swal from 'sweetalert2';
import API from '../../API';
import { BankList,BankName } from '../../Utils/Bank';
import "react-datepicker/dist/react-datepicker.css";
import '../../Assets/CSS/Horapa.css';
import { Modal } from 'reactstrap';

const PageQuotationRemark =()=> {
    const RedirectTo = useNavigate()
    const { register, getValues, setValue } = useForm();

    const ref = useRef();
    const [_PageMode,setPageMode] = useState(1);
    const [_ModalState,setModalState] = useState(false);
    const [_QuotationRemarkName,setQuotationRemarkName] = useState('');
    const [_Remark,setRemark] = useState('');
    const [_QuotationRemarkID,setQuotationRemarkID] = useState(undefined);

    useEffect(() => {
        (async () => {

        })();

        return ()=>{ }
    }, []);
    
    
    const _configs = {
        "order": [[0, "asc"]],
        pageLength: 10,
        orderMulti: true
    }
    const _ajax  = {
        'url': '/Setting/Quotation/Remark/List',
        'method': 'POST',
        'caches': false,
        'global': false,
        'data': function (d) {}
    };

    const _Buttons = (d,r) => (
        <div className='btn-group btn-group-sm'>
            <button type='button' title='ลบ' className='btn btn-danger text-white' onClick={() => _Delete(d,r)}>ลบ</button>
        </div>
    )
    const _column = [
        { data: "QuotationRemarkName", name: "QuotationRemarkName", autoWidth: true },
        { data: "Remark", name: "Remark", autoWidth: true,render(data){
            let _str = '';    
            String(data).split("\n").map((v,i)=>{
                _str += v+'<br/>';
            })
            return _str;
        } },
        {data: "UpdateDate", name: "UpdateDate", autoWidth: true,render(data){
            return DateFormat(data,true);
        }},
        {
            data: "QuotationRemarkId", name: "QuotationRemarkID", autoWidth: true,
            render: (data) => { return ''; },
            createdCell: (td, cellData,rowData) => createRoot(td).render(_Buttons(cellData,rowData))
        }
    ]
    const _ColumnDefs = [
    ]
    
    const _UpdateTable =()=>{
        ref.current?.load();
    }


    const _Delete = async(QuotationRemarkID,val)=>{
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการลบ หรือไม่ ?',
            text : val.QuotationRemarkName,
            showCancelButton : true,
            confirmButtonText: 'ลบ',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }
            API({method : "delete",url:"/Setting/Quotation/Remark/"+QuotationRemarkID}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                ref.current?.load();
            }).catch(res => {
                return;
            });
        });
    }

    const _CreateNew = ()=>{
        setQuotationRemarkID(null);
        setQuotationRemarkName('');
        setRemark('');
        setModalState(true);
    }
    const _Save = async()=>{
        if(_QuotationRemarkName.length === 0 || _QuotationRemarkName === ''){
            Swal.fire({
                icon: 'error',
                title: 'โปรดระบชื่อเงื่อนไข'
            });
            return;
        }
        const _reqObj = {
            QuotationRemarkID:_QuotationRemarkID,
            QuotationRemarkName:_QuotationRemarkName,
            Remark:_Remark
        }
        Swal.fire({
            icon: 'warning',
            title: 'ต้องการบันทึก หรือไม่ ?',
            text : '',
            showCancelButton : true,
            confirmButtonText: 'บันทึก',
            confirmButtonColor:'#d33',
            cancelButtonText:'ยกเลิก'
        }).then((r)=>{
            if(!r.isConfirmed){
                return;
            }

            API({method : 'post',url:'/Setting/Quotation/Remark/Save',data:_reqObj}).then(res=>{
                if(!res.data.Status){
                    Swal.fire({
                        icon: 'error',
                        title: res.data.Message
                    });
                    return;
                }
                setModalState(false);
                ref.current?.load();
            }).catch(res => {
                if(res.response.data != undefined){
                    Swal.fire({
                        icon: 'error',
                        title: res.code,
                        text: res.response.data.Message
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'การเชื่อมต่อผิดพลาด',
                        text: res.message+' ['+res.code+']',
                    });
                }
                

            });
        });
    }
    return (
        <>
        <React.Fragment>
            <div className='container-fluid'>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                    <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>จัดการข้อมูลหมายเหตุใบเสนอราคา</h3>
                            </div>
                            <div className='card-body' style={{'minHeight':'300px'}}>
                                <div className='row d-flex justify-content-end'>
                                        <div className='col-4 col-lg-3 mt-2 text-end'>
                                            <button type='button'  className='btn btn-primary text-white' onClick={()=>{_CreateNew()}}>เพิ่มข้อมูลหมายเหตุใบเสนอราคา</button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-12 table-responsive">
                                            <table id="QuotationRemarkList" className='table w-100' role='grid'>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center textcenter">ชื่อหมายเหตุใบเสนอราคา</th>
                                                        <th className="text-center textleft">รายละเอียด</th>
                                                        <th className="text-center textcenter">แก้ไข</th>
                                                        <th className="text-center no-sort"></th>
                                                    </tr>
                                                </thead>
                                                <DataTable TableID={"QuotationRemarkList"} Column={_column} Configs={_configs} ref={ref} ajax={_ajax} ColumnDefs={_ColumnDefs} />
                                            </table>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        <Modal isOpen={_ModalState} className={'modal-xl'} centered={true} unmountOnClose={true}>
            <div className="modal-header">
                <h5 className="modal-title">เพิ่มข้อมูลหมายเหตุใบเสนอราคา</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>{setModalState()}}></button>
            </div>
            <div className='modal-body'>
                <div className="row mt-2 mb-2">
                    <div className="col-md-12">
                        <label className="form-label">ชื่อหมายเหตุใบเสนอราคา</label>
                        <input type='text' className='form-control' value={_QuotationRemarkName} onChange={(e)=>{setQuotationRemarkName(e.target.value);}}/>
                    </div>
                </div>
                <div className='row mt-2 mb-2'>
                    <div className='col-md-12'>
                        <label className="form-label">หมายเหตุ</label>
                        <textarea className='form-control' style={{minHeight:'200px'}} value={_Remark} onChange={(e)=>{setRemark(e.target.value);}}></textarea>
                    </div>
                </div>
                <div className='row mt-4 mb-2 justify-content-end'>
                    <div className='col-md-2 text-end'>
                        <button type='button' className='btn btn-success' onClick={(()=>{_Save();})}>บันทึก</button>
                    </div>
                </div>
            </div>
        </Modal>
        </>
    )
}

export default React.memo(PageQuotationRemark)
