import React,{forwardRef,useImperativeHandle,useEffect } from 'react';
import "datatables.net-dt/js/dataTables.dataTables";
import {Helmet} from "react-helmet";
import {GetAuthorization,APIURL} from '../../Utils/Function';
import $ from "jquery";

const DataTable = forwardRef((props,ref) =>{
    const TableID = props.TableID;
    var _Config = {};
    let _columnDefs = [];
    if(props.ColumnDefs != undefined) _columnDefs = props.ColumnDefs;
    if(props.ajax === undefined){
        _Config = {
          processing: true,
          serverSide: false,
          orderMulti: false,
          searching: true,
          language: {
            processing: `<h2 class='text-center text-danger col-md-12 mt-1' style="background-color:#fff;">Loading..</h2>`,
            loadingRecords: "Loading.."
          },
          columnDefs: [
                { className: "text-center", targets: 'textcenter' },
                { className: "text-left", targets: 'textleft' },
                { className: "text-end", targets: 'textright' },
                { className: "text-end", targets: 'textend' },
                { targets: 'no-sort', orderable: false }
            ],
            ...props.Configs
          };
    }else{
        _Config = {
          processing: true,
          serverSide: true,
          filter: true,
          orderMulti: false,
          searching: true,
          responsive: true,
          ajax: props.ajax,
          language: {
            processing: `<h2 class='text-center text-danger col-md-12 mt-1' style="background-color:#fff;">Loading..</h2>`,
            loadingRecords: "Loading.."
          },
            fnRowCallback: function (
              nRow,
              aData,
              iDisplayIndex,
              iDisplayIndexFull
            ) {
              return nRow;
            },
            columnDefs: [
                { className: "text-center", targets: 'textcenter' },
                { className: "text-left", targets: 'textleft' },
                { className: "text-end", targets: 'textright' },
                { className: "text-end", targets: 'textend' },
                { targets: 'no-sort', orderable: false }
            ],
            data: props.data,
            columns: props.Column,
            ...props.Configs
          };
    }
    // eslint-disable-next-line 
    useEffect(() => {

        if(GetAuthorization() != null){
            $.ajaxSetup({
                headers: { 'Authorization':GetAuthorization() },
                beforeSend: function(xhr, options) {
                    if( options.url.indexOf('http') !== 0 ) {
                        options.url = APIURL() + options.url;
                    }
                }
            });
        }else{
            $.ajaxSetup({
                beforeSend: function(xhr, options) {
                    if( options.url.indexOf('http') !== 0 ) {
                        options.url = APIURL() + options.url;
                    }
                }
            });
        }
       
        if (!$.fn.DataTable.isDataTable('#'+TableID)) {
            $(document).ready(function () {
              $.fn.dataTable.ext.errMode = 'none';
              setTimeout(function () {
                $('#'+TableID).DataTable(_Config);
              }, 100);
            });
        }
        return ()=>{
            $('#'+TableID).DataTable().destroy();
        }
        // eslint-disable-next-line 
    }, []);


    useImperativeHandle(ref, () => ({
        load(){
            if(props.ajax === undefined){
              $('#'+TableID).DataTable().draw();
            }
            else
            $('#'+TableID).DataTable().ajax.reload();
        }
      }));
      return(
        <>
            <Helmet>
            <link href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.0/css/bootstrap.min.css" rel="stylesheet"/>
            <link href="https://cdn.datatables.net/v/bs5/jq-3.7.0/dt-2.1.4/datatables.min.css" rel="stylesheet"/>

            <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
            <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.0/js/bootstrap.bundle.min.js"></script>
            <script src="https://cdn.datatables.net/v/bs5/jq-3.7.0/dt-2.1.4/datatables.min.js"></script>
            </Helmet>
        </>
      )
});

export default React.memo(DataTable)